// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agencies-strapi-agencies-slug-js": () => import("./../../../src/pages/agencies/{strapiAgencies.slug}.js" /* webpackChunkName: "component---src-pages-agencies-strapi-agencies-slug-js" */),
  "component---src-pages-find-your-rank-js": () => import("./../../../src/pages/find-your-rank.js" /* webpackChunkName: "component---src-pages-find-your-rank-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indium-js": () => import("./../../../src/pages/indium.js" /* webpackChunkName: "component---src-pages-indium-js" */),
  "component---src-pages-tips-strapi-articles-slug-js": () => import("./../../../src/pages/tips/{strapiArticles.slug}.js" /* webpackChunkName: "component---src-pages-tips-strapi-articles-slug-js" */)
}

